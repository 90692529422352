<template>
  <v-dialog
        ref="dialog"
        v-model="modal"
        persistent
        :disabled="isDone && isOnceOff"
        :width="width">
        <template v-slot:activator="{ on, attrs }">
            <v-btn 
                :icon="icon != null && text == null"
                :text="icon == null && text != null" 
                :block="block"
                :class="buttonClass"
                :small="small"
                v-bind="attrs"
                v-on="on"
                @click.stop="openDialog"
                :loading="loading"
                :title="title">
                <v-icon v-if="isDone && doneIcon" :small="small" :left="text != null">{{ doneIcon }}</v-icon>
                <v-icon v-else :small="small" :left="text != null">{{ icon }}</v-icon>
                {{ text != null ? text : '' }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title>{{ label }}</v-card-title>
            <v-card-text>
                <v-form
                    v-if="data"
                    v-model="form"
                    class="mx-sm-4"
                    ref="form"
                    dense
                    scrollable>
                    <slot v-bind:item="data" />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn text class="warning--text" @click="close">{{ cancelText }}</v-btn>
                <v-btn text class="success--text" @click="save">{{ okText }}</v-btn>
                <v-spacer />
                <slot name="buttons" v-bind:item="data" />
            </v-card-actions>
        </v-card>
    </v-dialog>    
</template>

<script>
export default {
    name: 'BT-Single-Action-Dialog',
    data: function() {
        return {        
            modal: false,
            data: null,
            form: null,
        }
    },
    props: {
        value: null,
        show: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },                
        text: {
            type: String,
            default: null
        },
        width: {
            type: String,
            default: 'auto'
        },
        okText: {
            type: String,
            default: 'OK'
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        color: {
            type: String,
            default: 'primary'
        },
        doneColor: {
            type: String,
            default: null
        },
        item: null,
        isOnceOff: {
            type: Boolean,
            default: false
        },
        isDone: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'mdi-arrow-right-circle'
        },
        doneIcon: {
            type: String,
            default: 'mdi-check'
        },
        title: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        getOnOpen: {
            type: Function,
            default: null
        },
        block: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: null
        },
        small: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        show: function(val) {
            this.modal = val;
        },
        value: function(val) {
            if (val) {                
                this.data = val;
            }          
        },
        item: function(val) {
            if (!this.value && val) {
                this.data = val;
            }
        }
    },
    mounted() {    
        if (this.getOnOpen == null) {
            if (this.value) {
                this.data = this.value;
            }    
            else if (this.item) {
                this.data = this.item;
            }      
        }  
        
        this.modal = this.show;
    },    
    methods: {
        save() {            
            this.$emit('ok', this.data); 
            this.modal = false;           
        },
        openDialog() {           
            if (!this.isOnceOff || this.isOnceOff && !this.isDone) {                
                if (this.getOnOpen != null) {                    
                    this.data = this.getOnOpen(this.item);
                }                

                this.$emit('open', this.data);
            }               
        },
        close() {            
            this.modal = false;
            this.$emit('close');
        }        
    }   
}
</script>